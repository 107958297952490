<template>
  <div class="container">
    <SingleNotice
      v-for="item in items"
      :key="item.id"
      :data="item"
      @close="readItem(item.id)"
      @hide="hideItem(item.id)"
    />
  </div>
</template>

<script>
import SingleNotice from "@/components/notice/SingleNotice.vue";
import { API } from "@/assets/js/api/api";

export default {
  name: "SingleNoticeList",
  components: { SingleNotice },
  computed: {
    items() {
      return this.$store.state.notices.unreadPushItems;
    },
    roleGroup() {
      return this.$store.getters.roleGroup;
    },
  },
  methods: {
    async readItem(val) {
      try {
        await API.notices.markAsRead(
          {
            notifications: [val],
            userID: this.$store.state.general.userInfo.id,
          },
          this.roleGroup
        );
        this.$store.commit({ type: "clearNotification", value: val });
      } catch (e) {
        console.log(e);
      }
    },
    hideItem(val) {
      this.$store.commit({ type: "clearPushNotification", value: val });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
