<template>
  <component
    :is="component"
    v-bind="linkProperties"
    class="v-button"
    :class="classes"
    :disabled="disabled"
    :type="!link && !href ? type : null"
    @click="handleClick"
  >
    <span v-if="$slots.leftAddon" class="v-button__addon is-left">
      <slot name="leftAddon"></slot>
    </span>
    <span v-if="$slots.default" class="v-button__text">
      <slot></slot>
    </span>
    <span v-if="$slots.rightAddon" class="v-button__addon is-right">
      <slot name="rightAddon"></slot>
    </span>
    <span v-if="$slots.centerAddon" class="v-button__addon is-center">
      <slot name="centerAddon"></slot>
    </span>
  </component>
</template>

<script>
export default {
  name: "VButton",

  emits: ["click"],

  props: {
    outline: Boolean,
    disabled: Boolean,
    small: Boolean,
    medium: Boolean,
    large: Boolean,
    icon: Boolean,
    block: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    href: String,
    link: {
      type: [String, Object],
      default: "",
    },
    active: Boolean,
    blank: {
      type: Boolean,
      default: false,
    },
    gray: {
      type: Boolean,
      default: false,
    },
    red: {
      type: Boolean,
      default: false,
    },
    nav: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    component() {
      if (this.href) {
        return "a";
      } else if (this.link) {
        return "n-link";
      } else {
        return "button";
      }
    },

    linkProperties() {
      let linkProperties = {};
      if (this.link) {
        if (!(typeof this.link === "string")) {
          linkProperties = { ...this.link };
        } else {
          linkProperties.to = this.link;
        }
      } else if (this.href) {
        linkProperties.href = this.href;
      }

      if (this.blank) {
        linkProperties.target = "_blank";
      }

      return linkProperties;
    },

    classes() {
      return [
        `v-button--default`,
        {
          "is-outline": this.outline,
          "is-disabled": this.disabled,
          "is-small": this.small,
          "is-medium": this.medium,
          "is-large": this.large,
          "is-icon": this.icon,
          "is-active": this.active,
          "is-grey": this.grey,
          "has-right-addon": this.$slots.rightAddon,
          "has-left-addon": this.$slots.leftAddon,
          nav: this.nav,
          "is-gray": this.gray,
          "is-red": this.gray,
          block: this.block,
        },
      ];
    },
  },

  methods: {
    handleClick(e) {
      if (this.disabled || this.loading) return;
      this.$emit("click", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px 16px;
  border-radius: 10px;
  outline: none;

  font-size: 16px;
  line-height: 22px;
  text-align: center;
  white-space: nowrap;
  font-weight: 600;
  transition: 0.4s;
  cursor: pointer;
  user-select: none;
  background-color: #f18e35;
  color: #fff;

  &.is-small {
    height: 32px;
    padding: 6px 14px;
    border-radius: 8px;

    font-size: 14px;
    line-height: 20px;

    &.is-outline {
      padding: 6px 12px;
    }
  }

  &.is-large {
    height: 52px;
    padding: 12px 20px;
    border-radius: 12px;

    font-size: 20px;
    line-height: 28px;

    &.is-outline {
      padding: 12px 18px;
    }
  }

  &.is-outline {
    padding: 10px 14px;
    background-color: transparent;
    color: #f18e35;
    box-shadow: none;
    border-color: #f18e35;
    border-style: solid;
    border-width: 2px;
  }

  &.is-active {
    border-color: #f5a94d;
    background-color: #f5a94d;
    color: #fff;
  }

  @include hover {
    &:hover {
      border-color: #f5a94d;
      background-color: #f5a94d;
      color: #fff;
    }
  }

  &:active {
    border-color: #f5a94d;
    background-color: #f5a94d;
    color: #fff;
  }

  &.nav {
    justify-content: flex-start;
    position: relative;
    background: #f6f6f6;
    color: #222222;
    height: 40px;
    width: 200px;
    padding: 8px 18px;
    border: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;

    @include hover {
      &:hover {
        border-color: #f5a94d;
        background-color: #f5a94d;
        color: #fff;
      }
    }

    &.is-active {
      border-color: #f5a94d;
      background-color: #f5a94d;
      color: #fff;
    }
  }

  &.is-disabled {
    border-color: #ebebeb;
    background-color: #f6f6f6;
    color: #858585;
    cursor: not-allowed;
  }

  /* Modificators */

  &.block {
    width: 100%;
  }

  /* End Modificators */

  /* Colors */

  &.is-gray {
    background: #ffffff;
    border-color: #858585;
    color: #858585;

    @include hover {
      &:hover {
        border-color: #adadad;
        background-color: #ffffff;
        color: #adadad;
      }
    }
  }

  &.is-red {
    background-color: #ffffff;
    border-color: #f03738;
    color: #f03738;

    @include hover {
      &:hover {
        background-color: #ffffff;
        border-color: #f03738;
        color: #f03738;
      }
    }
  }

  /* End Colors */

  &__text {
    display: flex;
    align-items: center;
    outline: none;
  }

  &__addon {
    display: flex;
    flex-shrink: 0;

    &.is-left {
      margin-right: 8px;
    }
    &.is-right {
      margin-left: 6px;
    }
    &.is-center {
      margin: 0 auto;
    }
  }
}
</style>
