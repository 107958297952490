<template>
  <div class="v-input" :class="classes">
    <div class="v-input__inner">
      <div v-if="isSearch" class="v-input__search-icon">
        <IconSearch class="frame-icon is-small" />
      </div>

      <input
        ref="input"
        v-model.trim="lazyValue"
        v-bind="$attrs"
        class="v-input__native"
        :name="name"
        :disabled="disabled"
        :required="required"
        :maxlength="maxLength"
        @keydown.enter="onEnter"
        @input="onInput"
        @change="onChange"
        @focus="onFocus"
        @blur="onBlur"
        :placeholder="placeholder"
        :type="currentType || null"
      />

      <div v-if="label" class="v-input__label">
        <span>{{ label }}</span>
        <span @click="$emit('click-label')">{{ clickableLabel }}</span>
      </div>

      <span v-if="required & !error" class="v-input__required"
        >*обязательное поле</span
      >

      <span v-if="error" class="v-input__required">{{ error }}</span>
      <span v-if="bottomMessage" class="v-input__required">{{
        bottomMessage
      }}</span>

      <transition name="fade-fast">
        <div
          v-if="lazyValue && isClear && !disabled && !readonly"
          class="v-input__clear-icon"
          @click.stop="onClear"
        >
          <IconCloseInput />
        </div>
      </transition>
    </div>

    <div v-if="type === 'password'" class="v-input__addon is-right">
      <svg
        class="v-input__button"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="onShowPasswordClick"
      >
        <template v-if="currentType === 'password'">
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 2.6087C7.09283 2.6087 5.54677 4.12703 5.54677 6C5.54677 7.87297 7.09283 9.3913 9 9.3913C10.9072 9.3913 12.4532 7.87297 12.4532 6C12.4532 4.12703 10.9072 2.6087 9 2.6087ZM7.14057 6C7.14057 4.99148 7.97306 4.17391 9 4.17391C10.0269 4.17391 10.8594 4.99148 10.8594 6C10.8594 7.00852 10.0269 7.82609 9 7.82609C7.97306 7.82609 7.14057 7.00852 7.14057 6Z"
              fill="#ADADAD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 0C5.14185 0 1.85128 2.37781 0.552357 5.72101C0.482548 5.90069 0.482548 6.09932 0.552357 6.279C1.85128 9.6222 5.14185 12 9 12C12.8582 12 16.1487 9.62219 17.4476 6.27899C17.5175 6.09932 17.5175 5.90069 17.4476 5.72101C16.1487 2.37781 12.8582 0 9 0ZM9 10.4348C5.93444 10.4348 3.29853 8.61044 2.1566 6C3.29853 3.38956 5.93444 1.56522 9 1.56522C12.0656 1.56522 14.7015 3.38956 15.8434 6C14.7015 8.61044 12.0656 10.4348 9 10.4348Z"
              fill="#ADADAD"
            />
          </svg>
        </template>
        <template v-else>
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 2.6087C7.09283 2.6087 5.54677 4.12703 5.54677 6C5.54677 7.87297 7.09283 9.3913 9 9.3913C10.9072 9.3913 12.4532 7.87297 12.4532 6C12.4532 4.12703 10.9072 2.6087 9 2.6087ZM7.14057 6C7.14057 4.99148 7.97306 4.17391 9 4.17391C10.0269 4.17391 10.8594 4.99148 10.8594 6C10.8594 7.00852 10.0269 7.82609 9 7.82609C7.97306 7.82609 7.14057 7.00852 7.14057 6Z"
              fill="#f18e35"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 0C5.14185 0 1.85128 2.37781 0.552357 5.72101C0.482548 5.90069 0.482548 6.09932 0.552357 6.279C1.85128 9.6222 5.14185 12 9 12C12.8582 12 16.1487 9.62219 17.4476 6.27899C17.5175 6.09932 17.5175 5.90069 17.4476 5.72101C16.1487 2.37781 12.8582 0 9 0ZM9 10.4348C5.93444 10.4348 3.29853 8.61044 2.1566 6C3.29853 3.38956 5.93444 1.56522 9 1.56522C12.0656 1.56522 14.7015 3.38956 15.8434 6C14.7015 8.61044 12.0656 10.4348 9 10.4348Z"
              fill="#f18e35"
            />
          </svg>
        </template>
      </svg>
    </div>
    <div v-if="isTableSearch" class="v-input__addon is-right">
      <svg
        class="v-input__button"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <IconSearch color="#F0811E"></IconSearch>
      </svg>
    </div>
  </div>
</template>

<script>
import IconCloseInput from "@/components/icons/IconCloseInput";
import IconSearch from "@/components/icons/IconSearch";

export default {
  name: "VInput",

  components: {
    IconSearch,
    IconCloseInput,
  },

  props: {
    name: String,
    value: [String, Number],
    min: [String, Number],
    max: [String, Number],
    maxLength: [Number],
    numberControl: Boolean,
    numberControlRange: {
      type: Number,
      default: 1,
    },
    placeholder: String,
    label: String,
    clickableLabel: String,
    type: {
      type: String,
      default: "text",
    },
    color: {
      type: String,
      default: "default",
    },
    disabled: Boolean,
    readonly: Boolean,
    required: Boolean,
    error: String,
    bottomMessage: String,
    isClear: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    isTableSearch: {
      type: Boolean,
      default: false,
    },
    isFocus: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:value"],

  data() {
    return {
      lazyValue: this.value || "",
      isFocused: false,
      currentType: "",
    };
  },

  computed: {
    classes() {
      return [
        `v-input--${this.color}`,
        {
          "is-active": this.lazyValue,
          "is-focused": this.isFocused,
          "is-disabled": this.disabled,
          "is-readonly": this.readonly,
          "has-label": this.label,
          error: this.error,
          "is-clear": this.isClear,
          "is-control": this.numberControl,
          "is-table-search": this.isTableSearch,
          "is-required": this.required,
          "is-search": this.isSearch,
        },
      ];
    },

    cleanValue() {
      return this.lazyValue;
    },
  },

  watch: {
    value(val) {
      if (val !== this.lazyValue) {
        this.lazyValue = val;
      }
    },
  },

  created() {
    this.currentType = this.type;
  },

  mounted() {
    if (this.isFocus) {
      this.$refs.input.focus();
    }
  },

  methods: {
    onEnter() {
      this.$refs.input.blur();
    },

    onFocus() {
      if (this.type === "hours") {
        this.lazyValue = this.lazyValue.replace(/\D/g, "");
      }
      this.isFocused = true;
      this.$refs.input.focus();
    },

    onBlur() {
      if (this.type === "hours") {
        if (this.lazyValue.includes(":")) {
          return;
        }
        let value = this.lazyValue;
        let hours = +value.slice(0, 2);
        let minutes = +value.slice(2);

        hours = Math.max(0, Math.min(hours, 23));
        minutes = Math.max(0, Math.min(minutes, 59));

        hours = hours.toString();
        if (hours.length < 2) {
          hours = 0 + hours;
        }

        minutes = minutes.toString();
        if (minutes.length < 2) {
          minutes = 0 + minutes;
        }

        this.lazyValue = `${hours}:${minutes}`;
      }
      this.isFocused = false;
      this.$emit("blur");
    },

    onChange() {
      if (this.type === "hours") {
        if (this.lazyValue.includes(":")) {
          return;
        }

        let value = this.lazyValue;
        let hours = +value.slice(0, 2);
        let minutes = +value.slice(2);

        hours = Math.max(0, Math.min(hours, 23));
        minutes = Math.max(0, Math.min(minutes, 59));

        hours = hours.toString();
        if (hours.length < 2) {
          hours = 0 + hours;
        }

        minutes = minutes.toString();
        if (minutes.length < 2) {
          minutes = 0 + minutes;
        }

        this.lazyValue = `${hours}:${minutes}`;
      }
      this.$emit("change");
    },
    onInput() {
      if (this.currentType === "number") {
        this.lazyValue = this.lazyValue
          .toString()
          .replace(/[^0-9.]/g, "")
          .replace(/(\..*)\./g, "$1");
      }
      if (this.type === "hours") {
        let value = this.lazyValue.replace(/\D/g, "");
        if (/^\d{0,2}$/.test(value)) {
          // Ограничиваем ввод до 2 цифр для часов
          this.lazyValue = value.slice(0, 2);
        } else if (/^\d{2}:\d{0,2}$/.test(value)) {
          // Ограничиваем ввод до 2 цифр для минут
          this.lazyValue = value.slice(0, 4);
        } else {
          // Удаляем любые другие символы
          this.lazyValue = value.slice(0, 4).replace(/\D/g, "");
        }
      }

      this.$emit("update:value", this.cleanValue);
    },

    onShowPasswordClick() {
      this.currentType = this.currentType === "password" ? "text" : "password";
    },

    onClear() {
      this.lazyValue = "";
      this.$emit("update:value", "");
    },
  },
};
</script>

<style lang="scss" scoped>
/*stylelint-disable */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

.v-input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid;
  background-color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  transition: border-color 0.2s ease;

  &.error {
    border-color: $red;

    .v-input__label {
      color: $red;
    }
  }

  &__native {
    width: 100%;
    height: 100%;
    padding: 0 24px 0 12px;
    border: none;
    background-color: transparent;
    outline: none;
    font-family: $font-family-primary;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  &.is-search {
    .v-input__native {
      padding: 0 24px 0 36px;
    }
  }

  &.is-table-search {
    .v-input__native::placeholder {
      color: #f0811e;
      opacity: 1;
    }
  }

  /* Colors */
  &--default {
    border-color: #d6d6d6;
    caret-color: #222222;

    .v-input__native {
      color: #000000;
    }

    .v-input__label {
      color: #858585;
    }

    .v-input__label {
      position: absolute;
      top: -4px;
      left: 0;
      transform: translatey(-100%);
      user-select: none;

      span:last-child {
        cursor: pointer;
        color: #f5a94d;
      }
    }

    &:hover:not(.is-focused):not(.is-disabled):not(.is-readonly) {
      border-color: #adadad;
    }

    &.is-focused {
      border-color: #f18e35;

      .v-input__label {
        color: #f18e35;
      }
    }

    &.is-disabled {
      border-color: #ebebeb;
      background-color: #f6f6f6;
      user-select: none;

      .v-input__native {
        color: #858585;
        user-select: none;
        pointer-events: none;
      }
    }

    &.is-readonly {
      border-color: #ebebeb;
      background-color: #f6f6f6;

      .v-input__native {
        color: #858585;
        pointer-events: none;
      }
    }
  }

  /* End colors */

  /* Modificators */

  &.has-label {
    margin-top: 18px;
  }
  &.is-focused {
    &:after {
      transform: scaleX(1);
    }
  }

  &.is-control {
    .v-input__native {
      text-align: center;
    }
  }

  &.is-required {
    margin-bottom: 16px;
  }

  &.is-table-search {
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #f0811e;
    .v-input__native {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #f0811e;
    }
  }

  /* End Modificators */

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__required {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #f03738;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__addon {
    display: flex;
    flex-shrink: 0;

    &.is-left {
      margin-right: 8px;
    }

    &.is-right {
      margin-top: 16px;
      margin-right: 10px;
    }

    & > svg {
      width: 24px;
      height: 2.4rem;
    }
  }

  &__button {
    width: 2.4rem;
    height: 2.4rem;
    color: #f18e35;
    cursor: pointer;
  }

  &__search-icon {
    position: absolute;
    top: 13px;
    left: 13px;
  }

  &__clear-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    color: #d6d6d6;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
</style>
