<template>
  <div class="SearchableInput">
    <VInput
      v-model:value="lazyValue"
      placeholder="Поиск"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      is-search
      is-clear
    />

    <transition name="popover">
      <div v-if="isOpened" class="body">
        <div
          v-for="(item, index) in results"
          :key="item.id || index"
          class="item"
          @click="onClick(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import VInput from "./VInput";
import debounce from "lodash.debounce";

export default {
  name: "SearchableInput",

  components: {
    VInput,
  },

  props: {
    items: {
      type: Array,
    },
    value: {
      type: String,
    },
  },

  data() {
    return {
      lazyValue: "",
      isOpened: false,
      selectedFlat: null,
      resultsCount: 3,
      results: [],
    };
  },

  watch: {
    lazyValue(newQuestion) {
      if (newQuestion === "") {
        this.$emit("update", this.lazyValue);
      }
    },
    value: {
      handler(newValue) {
        this.lazyValue = newValue;
      },
      immediate: true,
    },
  },

  methods: {
    getResults() {
      let res = this.items;

      this.results = [
        ...new Set(
          res
            .filter((e) =>
              this.lazyValue
                ? e.name
                    .toLowerCase()
                    .includes(this.lazyValue.toLocaleLowerCase())
                : !this.lazyValue
            )
            .slice(0, this.resultsCount)
        ),
      ];
    },

    onInput: debounce(async function () {
      this.getResults();
      this.isOpened = !!this.results.length;
    }, 300),

    onFocus() {
      if (!this.lazyValue) {
        this.onInput();
      } else {
        this.isOpened = false;
      }
    },

    onBlur() {
      if (this.results.length === 1) this.onClick(this.results[0]);
      this.isOpened = false;
    },

    onClick(item) {
      this.selectedFlat = item;
      this.lazyValue = item.name;
      this.$emit("update", item.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.SearchableInput {
  position: relative;
}

.body {
  position: absolute;
  z-index: 90;
  display: flex;
  align-items: stretch;
  width: 100%;
  max-height: 300px;
  border-radius: 8px;
  margin-top: 8px;
  background-color: white;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);

  &:global(.popover-enter-active),
  &:global(.popover-leave-active) {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  &:global(.popover-enter),
  &:global(.popover-leave-to) {
    opacity: 0;
    transform: translate3d(0, 8px, 0);
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
}

.item {
  padding: 9px 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2a2a30;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #ebebeb;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
}
</style>
