export const CUISINES = {
  1: "Русская",
  2: "Американская",
  3: "Греческая",
  4: "Грузинская",
  5: "Европейская",
  6: "Индонезийская",
  7: "Испанская",
  8: "Итальянская",
  9: "Кавказская",
  10: "Казахская",
  11: "Китайская",
  12: "Корейская",
  13: "Мексиканская",
  14: "Немецкая",
  15: "Паназиатская",
  16: "Тайская",
  17: "Турецкая",
  18: "Узбекская",
  19: "Французская",
  20: "Японская",
  21: "Авторская",
  22: "Адыгейская",
  23: "Азербайджанская",
  24: "Азиатская",
  25: "Арабская",
  26: "Армянская",
  27: "Веганская",
  28: "Вегетарианская",
  29: "Восточная",
  30: "Вьетнамская",
  31: "Дагестанская",
  32: "Домашняя",
  33: "Еврейская",
  34: "Израильская",
  35: "Ливанская",
  36: "Молекулярная",
  37: "Рыбная",
  38: "Сербская",
  39: "Средиземноморская",
  40: "Уйгурская",
  41: "Украинская",
  42: "Халяльная",
  43: "Чешская",
};

export const FEATURES = {
  1: "Алкоголь",
  2: "Бизнес-ланч",
  3: "Большие компании",
  4: "Детская комната",
  5: "Для людей с инвалидностью",
  6: "Доставка",
  7: "Живая музыка",
  8: "Летняя веранда",
  9: "Можно с животными",
  10: "Можно с ноутбуком",
  11: "Открытая кухня",
  12: "Wi-Fi",
  13: "Завтрак",
};

export const RESTAURANT_TYPES = {
  1: "Бар",
  2: "Ресторан",
  3: "Кафе",
  4: "Закусочная",
  5: "Столовая",
};

export const JOURNAL_ROOT_EVENTS = {
  601: "Создание администратора",
  602: "Редактирование администратора",
  603: "Удаление администратора",
  604: "Сброс пароля администратора",
};

export const JOURNAL_ADMIN_EVENTS = {
  101: "Создание заведения",
  102: "Изменение заведения",
  103: "Привязка заведения",
  104: "!ЗАРЕЗЕРВИРОВАН!",
  105: "Удаление заведения",
  201: "Создание отзыва",
  202: "Модерирование отзыва",
  203: "Удаление отзыва",
  301: "Создание владельца",
  302: "Обновление владельца",
  303: "Удаление владельца",
  304: "Смена пароля у бизнес-аккаунта",
  401: "Создание ИП",
  402: "Обновление ИП",
  403: "Удаление ИП",
  501: "Создание ЮЛ",
  502: "Обновление ЮЛ",
  503: "Удаление ЮЛ",
  601: "Создание пользователя Евисо",
  602: "Обновление пользователя Евисо",
  603: "Удаление пользователя Евисо",
  604: "Сброс пароля пользователя Евисо",
  701: "Создание команды",
  702: "Обновление команды",
  703: "Удаление команды",
  1601: "Создание маркетолога",
  1602: "Обновление маркетолога",
  1603: "Удаление маркетолога",
  1604: "Удаление маркетолога с владельцем",
  1701: "Создание хостеса",
  1702: "Обновление хостеса",
  1703: "Удаление хостеса",
  1704: "Удаление хостеса с владельцем",
  1801: "Создание или обновление схемы посадки",
  2001: "Создание бронирования",
  2002: "Обновление бронирования",
  2003: "Завершение бронирования",
  2004: "Завершение всех бронирований для заведения",
  3001: "Создание счета-оферта",
  3002: "Создание акта",
  3003: "Проведение платежа вручную",
};

export const WEEKDAYS = {
  0: "SUN",
  1: "MON",
  2: "TUE",
  3: "WED",
  4: "THU",
  5: "FRI",
  6: "SAT",
};

export const WEEKDAYS_RUSSIAN = {
  MON: "ПН",
  TUE: "ВТ",
  WED: "СР",
  THU: "ЧТ",
  FRI: "ПТ",
  SAT: "СБ",
  SUN: "ВС",
};

export const MONTHS = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];
export const REVIEW_STATUSES = {
  1: "На рассмотрении",
  2: "Опубликовано",
  3: "Отклонен",
};
export const EVISO_ROLES = [
  "ROOT",
  "ADMIN",
  "HEAD",
  "MANAGER",
  "MODERATOR",
  "SECRETARY",
];
export const BUSINESS_ROLES_ENG = ["MAJOR", "HOSTESS", "MARKETER"];

export const BUSINESS_ROLES = {
  1: "Владелец",
  2: "Хостес",
  3: "Маркетолог",
};
export const EDO_COUNTERPARTY_STATUSES = {
  1: "ЭДО приглашение не принято",
  2: "ЭДО приглашение принято",
  3: "ЭДО заключен",
  4: "Заключен бумажный",
  5: "ЭДО приглашение не принято",
};
export const CHANGE_DELETE_BUTTON_TIME = 500;

export const PERIODS_DROPDOWN_OPTIONS = [
  { value: 0, label: "За день" },
  { value: 1, label: "За неделю" },
  { value: 2, label: "За месяц" },
];

export const CATERING_TABLE_COLUMNS = [
  { value: 1, label: "Название" },
  { value: 2, label: "Услуги в тарифе" },
  { value: 3, label: "Тарифный план" },
  { value: 4, label: "Адрес" },
  { value: 5, label: "Кухня" },
  { value: 6, label: "Владелец" },
  { value: 7, label: "ID" },
  { value: 8, label: "Дата создания" },
  { value: 9, label: "Менеджер" },
  { value: 10, label: "Тип заведения" },
  { value: 11, label: "Вместимость" },
  { value: 12, label: "Средний чек" },
  { value: 13, label: "Карта посадки" },
];

export const BOOKING_STATUSES = {
  1: "Ожидание",
  2: "Отменено",
  3: "Гость за столом",
  4: "Не пришел",
};

export const CLIENT_BOOKING_STATUSES = {
  1: "Ожидание",
  2: "Отменено",
  3: "Состоялась",
  4: "Не пришли",
};

export const DOCUMENT_STATUSES = {
  1: "Отправлено",
  2: "В обработке",
  3: "Беклог",
  4: "Возвращено",
  5: "Все",
};

export const DOCUMENT_TYPES = {
  1: "Акт оказания услуг",
  2: "Счёт-оферта",
};

export const DOCUMENT_SIGN_TYPES = {
  1: "Подписано",
  2: "Не подписано",
  3: "Подписано ЭЦП",
  4: "Не подписано ЭЦП",
};

export const DOCUMENT_TYPE_STATUSES = {
  1: "Бумажный",
  2: "Электронный",
  3: "Все",
};
export const PAYMENT_STATUSES = {
  1: "Оплачен",
  2: "Не оплачен",
};

export const PAYMENT_PROBLEMATIC_STATUSES = {
  1: "Обрабатывается",
  2: "Возврат",
  3: "Проведен автомат.",
  4: "Проведен вручную",
};

export const PAYMENT_PROBLEMATIC_TABLE_STATUSES = {
  1: "Все",
  2: "Возврат",
  4: "Обработан",
};

export const REGIONS_TIME_UTC = {
  "Свердловская область": "5",
};

export const ALL_ROLES = {
  ROOT: "Супер администратор",
  ADMIN: "Администратор системы",
  HEAD: "Руководитель",
  MANAGER: "Менеджер",
  MODERATOR: "Модератор",
  SECRETARY: "Секретарь",
  MAJOR: "Руководитель",
  HOSTESS: "Хостес",
  MARKETER: "Маркетолог",
  CLIENT: "Клиент",
};

export const TARIFFS_STATUSES = {
  1: "Опубликован",
  2: "Выключен",
};

export const DOCUMENTS_TABLE_STATUSES = {
  1001: "Не обработано",
  1002: "Отправлено",
  1003: "Не отправлено",
  2001: "Не обработано",
  2002: "Отправлено",
  2003: "Не отправлено",
  2004: "Возвращено",
};

export const DOCUMENTS_STATUSES = {
  1001: "Счет-оферты — Не обработано",
  1002: "Счет-оферты — Возвращено",
  1003: "Счет-оферты — Отправлено",
  2001: "Акт — Не обработано",
  2002: "Акт — Не отправилось",
  2003: "Акт — Отправлено",
  2004: "Акт — Возвращено",
};

export const INVOICE_DOCUMENTS_STATUSES_OPTIONS = {
  1: "Не обработано",
  2: "Возвращено",
  3: "Отправлено",
};

export const ACT_DOCUMENTS_STATUSES_OPTIONS = {
  1: "Не обработано",
  2: "Не отправилось",
  3: "Отправлено",
  4: "Возвращено ",
};

export const ACT_PAYMENT_STATUSES = {
  1001: "Не оплачен",
  1002: "Не оплачен",
  1003: "Не оплачен",
  1004: "Оплачен",
};

export const DOCUMENTS_TYPES = {
  act: "Акт",
  offerinvoice: "Счет-оферты",
};
