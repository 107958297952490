<template>
  <div class="inner">
    <h2>{{ title }}</h2>
    <div>
      <p class="message">
        <span>Мы отправили текстовое сообщение с кодом проверки на </span>
        <span class="message-bold">{{ contacts }}</span>
      </p>
      <form
        class="form"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <div class="inputs" v-show="isInputShown">
          <VInput
            v-model:value="num0"
            class="input"
            :max-length="1"
            @change="error = ''"
            :is-clear="false"
            @input="changeInput"
            ref="0"
            name="0"
          />
          <VInput
            v-model:value="num1"
            class="input"
            :max-length="1"
            @change="error = ''"
            @keyup.delete="deleteHandler(1)"
            :is-clear="false"
            @input="changeInput"
            ref="1"
            name="1"
          />
          <VInput
            v-model:value="num2"
            class="input"
            :max-length="1"
            @change="error = ''"
            @keyup.delete="deleteHandler(2)"
            :is-clear="false"
            @input="changeInput"
            ref="2"
            name="2"
          />
          <VInput
            v-model:value="num3"
            class="input"
            :max-length="1"
            @change="error = ''"
            @keyup.delete="deleteHandler(3)"
            :is-clear="false"
            ref="3"
          />
        </div>

        <div v-if="!isTimerGone" class="timer">
          <div class="bar">
            <div
              class="bar-progress"
              :style="{ width: `${(timer / 30) * 100}%` }"
            ></div>
          </div>
          <span>{{ timer }}</span>
        </div>

        <p v-else class="timer-info">Не получили код проверки?</p>

        <p v-if="error" class="error">{{ error }}</p>

        <p v-else class="attempt">
          Осталось
          <span class="attempt _bold">
            {{
              `${attempts} ${
                attempts === 2 || attempts === 3 ? "попытки" : "попытка"
              }`
            }}
          </span>
        </p>

        <div v-if="isTimerGone" class="buttons">
          <AsyncButton class="button" type="submit" :is-pending="isPending">
            Отправить еще раз
          </AsyncButton>
        </div>
      </form>
    </div>
    <IconPopupCross
      v-if="screenWidth !== 'mobile' && !isEvisoBusinessAuth"
      class="modal-cross"
      @click="$store.dispatch('closeModal')"
    />
  </div>
  <IconBack
    v-if="screenWidth !== 'mobile' && isEvisoBusinessAuth"
    class="arrow"
    @click="onArrowClickHandler"
  />
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import IconBack from "@/components/icons/IconBack.vue";
import AuthLoginModal from "@/components/modals/auth/AuthLoginModal.vue";

const store = useStore();
const route = useRoute();

const screenWidth = computed(() => {
  return store.getters.screenType;
});

const isEvisoBusinessAuth = computed(() => {
  return route.name === "auth-biz" || route.name === "auth-eviso";
});

const onArrowClickHandler = () => {
  store.dispatch("openModal", {
    value: {
      component: AuthLoginModal,
      props: null,
    },
  });
};
</script>

<script>
import AsyncButton from "@/components/ui/AsyncButton.vue";
import VInput from "@/components/ui/VInput.vue";
import jwt_decode from "jwt-decode";
import { API_WITHOUT_GUARDS } from "@/assets/js/api/apiWithoutGuards";
import { API } from "@/assets/js/api/api";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";
import AuthRegistrationSuccessModal from "@/components/modals/auth/AuthRegistrationSuccessModal.vue";
import AuthRestorationPasswordModal from "@/components/modals/auth/AuthRestorationPasswordModal.vue";
import AuthBannedPhoneModal from "@/components/modals/auth/AuthBannedPhoneModal.vue";

export default {
  name: "AuthPhoneCodeModal",

  components: {
    IconPopupCross,
    AsyncButton,
    VInput,
  },

  props: {
    contacts: {
      type: String,
      required: true,
    },
    roleType: {
      type: String,
      required: true,
    },
    phoneCodeType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      num0: "",
      num1: "",
      num2: "",
      num3: "",
      error: "",
      isPending: false,
      timer: 30,
      attempts: 3,
      isInputShown: true,
    };
  },

  computed: {
    title() {
      return this.phoneCodeType === "restoration"
        ? "Восстановление пароля"
        : "Подтвердите номер телефона";
    },
    isTimerGone() {
      return this.timer <= 0;
    },
    completed() {
      return !!(this.num0 && this.num1 && this.num2 && this.num3);
    },
  },

  watch: {
    async completed(val) {
      if (val) {
        try {
          this.isPending = true;
          this.attempts = this.attempts - 1;
          if (this.phoneCodeType === "restoration") {
            if (this.roleType === "client") {
              await API_WITHOUT_GUARDS.client.resetPassword({
                code: `${this.num0}${this.num1}${this.num2}${this.num3}`,
                phone: this.contacts,
              });
            } else if (this.roleType === "eviso") {
              const res = await API_WITHOUT_GUARDS.eviso.recoverPassword({
                code: `${this.num0}${this.num1}${this.num2}${this.num3}`,
                phoneOrEmail: this.contacts,
              });
              localStorage.setItem("accessToken", res.data.accessToken);
              localStorage.setItem("refreshToken", res.data.refreshToken);
              const token = res.data.accessToken;
              const decoded = jwt_decode(token);
              this.$store.commit({ type: "setjwtAT", value: decoded });
            } else {
              const res = await API_WITHOUT_GUARDS.business.recoverPassword({
                code: `${this.num0}${this.num1}${this.num2}${this.num3}`,
                phoneOrEmail: this.contacts,
              });
              localStorage.setItem("accessToken", res.data.accessToken);
              localStorage.setItem("refreshToken", res.data.refreshToken);
              const token = res.data.accessToken;
              const decoded = jwt_decode(token);
              this.$store.commit({ type: "setjwtAT", value: decoded });
            }

            this.$store.dispatch("openModal", {
              value: {
                component: AuthRestorationPasswordModal,
                props: {
                  contacts: this.contacts,
                  roleType: this.roleType,
                },
              },
            });
          } else {
            const res = await API_WITHOUT_GUARDS.client.verifySMS({
              code: `${this.num0}${this.num1}${this.num2}${this.num3}`,
              phone: this.contacts,
            });
            this.$store.dispatch("setLogin", { value: true });
            this.$store.commit({
              type: "setUser",
              value: res.data.user,
            });
            localStorage.setItem("accessToken", res.data.jwt.accessToken);
            localStorage.setItem("refreshToken", res.data.jwt.refreshToken);
            const token = res.data.jwt.accessToken;
            const decoded = jwt_decode(token);
            this.$store.commit({ type: "setjwtAT", value: decoded });
            const user = await API.client.getUser(decoded.preferred_username);
            this.$store.commit({ type: "setUserInfo", value: user.data });
            this.$store.dispatch("openModal", {
              value: {
                component: AuthRegistrationSuccessModal,
              },
            });
          }
        } catch (e) {
          if (e.response.status === 429) {
            this.$store.dispatch("openModal", {
              value: {
                component: AuthBannedPhoneModal,
                props: {
                  contacts: this.contacts,
                  message: e.response.data.message,
                },
              },
            });
          } else if (e.response.status === 425) {
            setTimeout(() => {
              this.error = "Cлишком много попыток ввода СМС";
              this.isInputShown = false;
            });
          } else {
            setTimeout(() => {
              this.error = "Неверный код";
            });
          }
          this.reset();
        } finally {
          this.isPending = false;
        }
      }
    },
    attempts(val) {
      if (!val) {
        this.isInputShown = false;
        setTimeout(() => {
          this.error = "Cлишком много попыток ввода СМС";
        });
      }
    },
  },

  methods: {
    async handleSubmit() {
      try {
        this.isInputShown = true;
        this.timer = 30;

        if (this.roleType === "client") {
          await API_WITHOUT_GUARDS.client.verifySMSResend({
            phone: this.contacts,
          });
        } else if (this.roleType === "eviso") {
          await API_WITHOUT_GUARDS.eviso.recoverPassword({
            phoneOrEmail: this.contacts,
          });
        } else {
          await API_WITHOUT_GUARDS.business.recoverPassword({
            phoneOrEmail: this.contacts,
          });
        }

        this.reset();
        this.attempts--;
      } catch (e) {
        if (e.response.status === 429) {
          this.$store.dispatch("openModal", {
            value: {
              component: AuthBannedPhoneModal,
              props: {
                contacts: this.contacts,
                message: e.response.data.message,
              },
            },
          });
        } else {
          setTimeout(() => {
            this.error = "Cлишком много попыток ввода СМС";
          });
        }
      }
    },
    reset() {
      this.num0 = "";
      this.num1 = "";
      this.num2 = "";
      this.num3 = "";
      this.$refs[0].onFocus();
      this.error = "";
    },
    changeInput(x) {
      let position = +x.target.name;
      this.$refs[position + 1].onFocus();
    },
    deleteHandler(val) {
      if (val === 1) {
        this.num0 = "";
        this.$refs[0].onFocus();
      }
      if (val === 2) {
        this.num1 = "";
        this.$refs[1].onFocus();
      }
      if (val === 3) {
        this.num2 = "";
        this.$refs[2].onFocus();
      }
    },
  },

  mounted() {
    setInterval(() => {
      this.timer -= 1;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 448px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

h2 {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #f0811e;
}

p {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #222222;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
}

.button {
  width: 100%;
}

.modal-cross {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.message {
  max-width: 326px;
  margin-bottom: 24px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #222222;

  .message-bold {
    color: #f18e35;
  }
}

.inputs {
  display: flex;
  justify-content: center;
}

.input {
  height: 40px;
  width: 40px;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  :deep(.v-input__native) {
    padding: 0;
    text-align: center;
  }
}

.arrow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200003;
  cursor: pointer;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px auto 0;
  padding: 4px 0;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &-info {
    margin-top: 8px;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  span {
    width: 28px;
  }

  .bar {
    width: 150px;
    height: 8px;
    margin-right: 8px;
    border-radius: 8px;
    background-color: #f6f6f6;
    overflow: hidden;

    &-progress {
      background-color: #f18e35;
      border-radius: 8px;
      height: 8px;
    }
  }
}

.attempt {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &._bold {
    font-weight: 600;
  }
}

.error {
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #f03738;
}
</style>
