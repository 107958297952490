<template>
  <div v-if="screenWidth === 'mobile' && !isCollapsed" class="filter _hide">
    <SearchableInput
      class="input _mobile"
      :value="filters.searchQueryMain"
      :items="initItems"
      @update="$emit('update', { type: 'searchQueryMain', value: $event })"
    />
    <div class="filterButtons">
      <BooleanFilter
        :value="filters.nowMain"
        @change="$emit('update', { type: 'nowMain', value: $event })"
      >
        Открыто сейчас
      </BooleanFilter>
    </div>
    <VButton small outline class="button _filters" @click="isCollapsed = true">
      <template #leftAddon><IconFilters /></template>
      <template #default>Все фильтры</template>
    </VButton>
  </div>
  <perfect-scrollbar v-else class="filter">
    <div v-if="screenWidth === 'mobile'" class="title">
      <span>Фильтры</span>
      <IconCross class="cross" @click="close()" />
    </div>

    <template v-if="!restaurants">
      <div v-if="screenWidth !== 'mobile'" class="input">
        <SearchableInput
          class="input"
          :value="filters.searchQuery"
          :items="initItems"
          @update="$emit('update', { type: 'searchQuery', value: $event })"
        /></div
    ></template>

    <VAccordion class="accordion">
      <template #head>Кухня</template>
      <template #body>
        <div class="filterButtons">
          <BooleanFilter
            v-for="(value, key) in currentKitchens"
            :value="filters.cuisineId.includes(+key)"
            @change="$emit('update-kitchen', +key)"
            :key="key"
          >
            {{ value }}
          </BooleanFilter>
        </div>
        <div v-if="!isMoreKitchens" class="more" @click="isMoreKitchens = true">
          Все кухни
        </div>
      </template>
    </VAccordion>

    <!--    <VAccordion v-if="screenWidth !== 'mobile'">-->
    <!--      <template #head>Время работы</template>-->
    <!--      <template #body>-->
    <!--        <div class="filterButtons">-->
    <!--          <BooleanFilter-->
    <!--            :value="filters.now"-->
    <!--            @change="$emit('update', { type: 'now', value: $event })"-->
    <!--          >-->
    <!--            Открыто сейчас-->
    <!--          </BooleanFilter>-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </VAccordion>-->

    <!--    <VAccordion>-->
    <!--      <template #head>Дата и время</template>-->
    <!--      <template #body>-->
    <!--        <div class="button-date">-->
    <!--          <div-->
    <!--            @click="-->
    <!--              $store.dispatch('openModal', {-->
    <!--                value: {-->
    <!--                  component: SelectFilterTimeModal,-->
    <!--                },-->
    <!--              })-->
    <!--            "-->
    <!--          >-->
    <!--            {{ filterTime ? getDate(filterTime) : "Выберите" }}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </VAccordion>-->

    <!--    <VAccordion v-if="filterTime">-->
    <!--      <template #head>Количество гостей</template>-->
    <!--      <template #body>-->
    <!--        <VInput type="number" v-model:value="visitors" class="input _number" />-->
    <!--      </template>-->
    <!--    </VAccordion>-->

    <VAccordion range>
      <template #head>Средний чек</template>
      <template #body>
        <VRange
          :value-range="filters.maxAverageCheck"
          :value-max="3000"
          format-marker="₽"
          @change="$emit('update', { type: 'maxAverageCheck', value: $event })"
        />
      </template>
    </VAccordion>

    <!--    <VAccordion range>-->
    <!--      <template #head>Расстояние до заведения</template>-->
    <!--      <template #body>-->
    <!--        <VRange-->
    <!--          :value-range="filters.maxDistance"-->
    <!--          :value-max="2500"-->
    <!--          format-marker="м"-->
    <!--          @change="$emit('update', { type: 'maxDistance', value: $event })"-->
    <!--        />-->
    <!--      </template>-->
    <!--    </VAccordion>-->

    <VAccordion class="accordion_last" range>
      <template #head>Вместимость</template>
      <template #body>
        <VRange
          :value-range="filters.maxCapacity"
          :value-max="250"
          @change="$emit('update', { type: 'maxCapacity', value: $event })"
        />
      </template>
    </VAccordion>

    <div class="btns">
      <VButton
        :small="screenWidth === 'desktop'"
        class="btn"
        outline
        @click="reset"
      >
        Сбросить всё
      </VButton>

      <VButton class="btn _accept" @click="save">Применить</VButton>
    </div>
  </perfect-scrollbar>
</template>

<script>
import SearchableInput from "@/components/ui/SearchableInput.vue";
import VButton from "@/components/ui/VButton.vue";
import VAccordion from "@/components/ui/VAccordion.vue";
import BooleanFilter from "@/components/ui/BooleanFilter.vue";
import VRange from "@/components/ui/VRange.vue";
import { CUISINES } from "@/assets/js/utils/consts";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import IconFilters from "@/components/icons/IconFilters.vue";
import IconCross from "@/components/icons/IconCross.vue";
import { getDate } from "@/assets/js/utils/helpers";

export default {
  name: "VClientFilter",
  components: {
    IconCross,
    IconFilters,
    VRange,
    VButton,
    SearchableInput,
    VAccordion,
    BooleanFilter,
    PerfectScrollbar,
  },

  props: {
    items: Array,
    initItems: Array,
    filters: Object,
    restaurants: Boolean,
    cateringPage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      itemsRest: this.items,
      kitchens: CUISINES,
      isMoreKitchens: false,
      isHovered: false,
      isCollapsed: false,
      visitors: 1,
    };
  },

  watch: {
    isCollapsed(val) {
      this.$emit("is-collapsed", val);
    },
  },

  methods: {
    getDate(value) {
      return getDate(value, true);
    },
    close() {
      this.isCollapsed = false;
    },
    reset() {
      this.$store.commit({ type: "setFilterTime", value: null });
      this.visitors = 1;
      this.$emit("reset");
    },
    save() {
      let date = this.filterTime;

      if (date) {
        date = new Date(this.filterTime);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        date.setUTCHours(hours);
        date.setUTCMinutes(minutes);
        date.setUTCSeconds(0);
      }

      this.$emit("filter", {
        date,
        visitors: this.visitors,
      });
      this.close();
    },
  },

  computed: {
    filterTime() {
      return this.$store.state.general.filterTime;
    },
    currentKitchens() {
      if (!this.isMoreKitchens) {
        const keys = Object.keys(this.kitchens).slice(0, 7);
        const result = {};
        for (let key of keys) {
          result[key] = this.kitchens[key];
        }
        return result;
      } else {
        return this.kitchens;
      }
    },
    screenWidth() {
      return this.$store.getters.screenType;
    },
  },
};
</script>

<style scoped lang="scss">
.filter {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: 320px;
  min-width: 320px;
  height: 100%;
  overflow-y: auto;
  padding: 24px 24px 0 24px;
  background-color: #fff;

  @include mobile {
    width: 100%;
    min-width: auto;
    padding-top: 0;

    .accordion {
      margin-top: 88px;

      &_last {
        margin-bottom: 36px;
      }
    }
  }

  &._hide {
    height: auto;
    overflow-y: hidden;
    padding: 24px 16px;
    width: 100%;
    min-width: auto;
  }

  & > div {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 146px;
    }
  }

  .btns {
    margin-bottom: 0;
  }

  .accordion_last {
    margin-bottom: 170px;
  }
}

.input {
  width: 264px;
  height: 40px;

  &._number {
    width: 100%;
  }

  &._mobile {
    width: 100%;
    margin-bottom: 16px;
  }
}

.btns {
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(320px - 32px);
  margin: 0 16px;
  background-color: white;
  z-index: 5;
  padding: 24px 0 32px 0;

  @include mobile {
    width: calc(100% - 32px);
  }
}

.btn {
  width: 111px;

  &:first-child {
    margin-bottom: 24px;
  }

  @include mobile {
    width: 100%;
  }

  &._accept {
    width: 100%;
  }
}

.filterButtons {
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: auto;
    margin-bottom: 12px;
  }

  & > div:not(:last-child) {
    margin-right: 12px;

    @include mobile {
      margin-right: 8px;
    }
  }
}

.more {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #f18e35;
  cursor: pointer;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background-color: #ffffff;
  position: fixed;
  z-index: 100;
  width: calc(100% - 48px);

  span {
    font-family: $font-family-secondary;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  svg {
    cursor: pointer;
  }
}

.select {
  width: 100%;
}

.button-date {
  display: flex;
  flex-direction: column;
  width: 100%;

  &._date {
    width: 210px;
  }

  &._time {
    width: 98px;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 4px;
  }

  div {
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    cursor: pointer;
    padding: 9px 12px;
    border: 1px solid #d6d6d6;
    border-radius: 8px;
  }
}
</style>
