<template>
  <header>
    <LogoIcon class="logo" />
  </header>
</template>

<script>
import LogoIcon from "@/components/icons/LogoIcon.vue";

export default {
  name: "HeaderWidget",
  components: { LogoIcon },
};
</script>

<style scoped lang="scss">
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 88px;
  padding: 20px 24px 20px 72px;
  background: #ffffff;
  border-bottom: 1px solid #ebebeb;

  @include mobile {
    padding-right: 16px;
  }
}

.logo {
  width: 70px;
  height: 40px;
}
</style>
